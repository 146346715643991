import React from "react";
import "./App.css";
import Home from "./components/Home/Home";
import About from "./components/About/About";
import Work from "./components/Work/Work";
import Contact from "./components/Contact/Contact";
import Footer from "./components/Footer/Footer";
import Navbar from "./components/Navbar/Navbar";
import Progress from "./components/Progress/Progress";
import { Container } from "@material-ui/core";
import { Helmet } from "react-helmet";

class App extends React.Component {
  state = {
    scrollPosition: 0,
  };

  listenToScrollEvent = () => {
    document.addEventListener("scroll", () => {
      requestAnimationFrame(() => {
        this.calculateScrollDistance();
      });
    });
  };

  calculateScrollDistance = () => {
    const scrollTop = window.pageYOffset; // how much the user has scrolled by
    const winHeight = window.innerHeight;
    const docHeight = this.getDocHeight();

    const totalDocScrollLength = docHeight - winHeight;
    const scrollPosition = Math.floor((scrollTop / totalDocScrollLength) * 100);

    this.setState({
      scrollPosition,
    });
  };

  getDocHeight = () => {
    return Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.offsetHeight,
      document.body.clientHeight,
      document.documentElement.clientHeight
    );
  };

  componentDidMount() {
    this.listenToScrollEvent();
  }

  render() {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    };
    return (
      <div className="app">
        <Helmet>
          <title>Ng Han Yi</title>
          <meta name="description" content="Ng Han Yi's portfolio website" />
          <meta
            name="keywords"
            content="ng han yi, nghanyi, ng han yi's portfolio, ng han yi's website "
          />
        </Helmet>
        <Navbar />
        <div className="app__content">
          <Progress scroll={this.state.scrollPosition + "%"} />
          <main>
            <Container fixed className="app__container">
              <Home />
              <About />
              <Work />
              <Contact />
            </Container>
          </main>
          <Footer />
        </div>
      </div>
    );
  }
}

export default App;
