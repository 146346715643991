import styled from "styled-components";

const Progress = styled.div`
  position: fixed;
  background: linear-gradient(
    to right,
    rgba(69, 149, 235, 1) ${(props) => props.scroll},
    transparent 0
  );
  transition: 0.1s ease;
  width: 100%;
  height: 2px;
  z-index: 3;
`;

export default Progress;
