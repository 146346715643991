import React from "react";

import "./Item.css";

import { Paper } from "@material-ui/core";

const Item = ({ xsImage, smImage, mdImage }) => {
  return (
    <Paper elevation={1} className="item">
      <img
        srcSet={`${xsImage} 343w, ${smImage} 552w, ${mdImage} 384w `}
        alt="Pic"
        width="100%"
      />
    </Paper>
  );
};

export default Item;
