import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

import "animate.css/animate.compat.css";

import { BLUE } from "../../../constants/colors";
import { Grid, Typography, makeStyles, Link } from "@material-ui/core";

const styles = makeStyles((theme) => ({
    intro: {
        lineHeight: 3,
        [theme.breakpoints.down("sm")]: {
            lineHeight: 2,
        },
    },
}));

const Introduction = () => {
    const classes = styles();
    const getYear = (date) => {
        date = new Date(date);
        var ageDifMs = Date.now() - date.getTime();
        var ageDate = new Date(ageDifMs);
        return Math.abs(ageDate.getUTCFullYear() - 1970);
    };

    return (
        <Grid item sm={12} md={7}>
            <ScrollAnimation animateIn='fadeInLeft' duration={2} delay={300}>
                <Typography
                    className={classes.intro}
                    align='justify'
                    variant='body1'
                >
                    My name is Han Yi and I am&nbsp;
                    {getYear("22 Aug 1997")} years old. I am currently a final
                    year Software Engineering student studying in the Singapore
                    Institute of Technology. I enjoy coding and love new
                    technologies. I used to be obese, which I have lost 29kgs
                    back in 2015. I love listening to chinese music as well as
                    singing chinese songs! Check out my covers on Spotify{" "}
                    <Link
                        href='https://open.spotify.com/artist/14vMg5otzlnr7WZ779lHMc?si=2kQh5Jo9TTmt8S-bXkWvlw&dl_branch=1'
                        target='_blank'
                        rel='noreferrer'
                        style={{ color: BLUE }}
                    >
                        here
                    </Link>
                    !
                </Typography>
            </ScrollAnimation>
        </Grid>
    );
};

export default Introduction;
