import React from "react";
import Introduction from "./Introduction/Introduction";
import ScrollAnimation from "react-animate-on-scroll";
import Gallery from "./Gallery/Gallery";

import "./About.css";
import "animate.css/animate.compat.css";

import { BLUE } from "../../constants/colors";
import { Grid } from "@material-ui/core";

const About = () => {
    return (
        <Grid
            id='about'
            className='about'
            container
            direction='column'
            alignItems='center'
            justify='center'
        >
            <ScrollAnimation animateIn='flipInY' animateOnce>
                <Grid item sm={12}>
                    <h1>
                        A little <span style={{ color: BLUE }}>about </span>me
                    </h1>
                </Grid>
            </ScrollAnimation>
            <Grid
                item
                container
                spacing={5}
                direction='row-reverse'
                justify='center'
            >
                <Gallery />
                <Introduction />
            </Grid>
        </Grid>
    );
};

export default About;
