import React from "react";
import Projects from "./Projects/Projects";

import "./Work.css";

import { Grid } from "@material-ui/core";

const Work = () => {
    return (
        <Grid
            container
            className='work'
            direction='column'
            alignItems='center'
            justify='center'
        >
            {/* <Github /> */}
            <Projects />
        </Grid>
    );
};

export default Work;
