import PersonIcon from "@material-ui/icons/Person";
import WorkIcon from "@material-ui/icons/Work";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import GitHubIcon from "@material-ui/icons/GitHub";
import LinkedInIcon from "@material-ui/icons/LinkedIn";

export const componentLinks = [
  {
    link: "about",
    title: "about",
    icon: <PersonIcon />,
  },
  {
    link: "projects",
    title: "portfolio",
    icon: <WorkIcon />,
  },
  {
    link: "contact",
    title: "contact",
    icon: <ContactMailIcon />,
  },
];

export const externalLinks = [
  {
    link: "https://github.com/nghanyi",
    title: "github",
    icon: <GitHubIcon />,
  },
  {
    link: "https://www.linkedin.com/in/nghanyi",
    title: "linkedin",
    icon: <LinkedInIcon />,
  },
];
