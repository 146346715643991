import React, { useState } from "react";
import useStyles from "./style";
import Popup from "./Popup";

import {
  Card,
  CardContent,
  CardMedia,
  Chip,
  Typography,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";

const Project = ({ project }) => {
  const classes = useStyles();
  const showMedia = useMediaQuery(useTheme().breakpoints.up("sm"));
  const [openPopup, setOpenPopup] = useState(false);
  const [state, setState] = useState({
    raised: false,
    shadow: 1,
  });

  return (
    <>
      <Card
        className={classes.card}
        classes={{ root: state.raised ? classes.cardHovered : "" }}
        onMouseOver={() => setState({ raised: true, shadow: 3 })}
        onMouseOut={() => setState({ raised: false, shadow: 1 })}
        onClick={() => setOpenPopup(true)}
      >
        {showMedia && (
          <>
            <CardMedia
              className={classes.media}
              image={project.image.default}
              title={project.title}
            />
            <div className={classes.overlay}>
              <Chip
                className={classes.year}
                label={project.year}
                style={{
                  backgroundColor: "#454443",
                  color: "#fff",
                  fontSize: 12,
                }}
                size="small"
              />
            </div>
          </>
        )}
        <CardContent className={classes.cardContent}>
          <Typography className={classes.cardTitle} variant="h5" align="center">
            {project.title}
          </Typography>
        </CardContent>
      </Card>
      <Popup
        project={project}
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      />
    </>
  );
};

export default Project;
