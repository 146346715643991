import React from "react";
import Image from "./Image/Image";
import Form from "./Form/Form";

import "./Contact.css";

import { Grid, Hidden } from "@material-ui/core";

const Contact = () => {
  return (
    <Grid
      className="contact"
      id="contact"
      container
      direction="column"
      alignItems="center"
      justify="center"
    >
      <Grid
        container
        item
        spacing={6}
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Hidden smDown>
          <Image />
        </Hidden>
        <Form />
      </Grid>
    </Grid>
  );
};

export default Contact;
