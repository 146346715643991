import xsPic1 from "../../../assets/images/MyPic1-343.jpg";
import smPic1 from "../../../assets/images/MyPic1-552.jpg";
import mdPic1 from "../../../assets/images/MyPic1-384.jpg";

import xsPic2 from "../../../assets/images/MyPic5-343.jpg";
import smPic2 from "../../../assets/images/MyPic5-552.jpg";
import mdPic2 from "../../../assets/images/MyPic5-384.jpg";
import pic3 from "../../../assets/images/MyPic6.jpeg";

export const xsImages = [pic3, xsPic1, xsPic2];
export const smImages = [pic3, smPic1, smPic2];
export const mdImages = [pic3, mdPic1, mdPic2];
