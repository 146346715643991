export const projectData = [
    {
        id: "onboard",
        year: 2022,
        title: "Onboard!",
        description:
            "A gamified android app for onboarding new employees in times of Covid19. The app is built with Kotlin with Firebase as backend. It uses technologies such as Google Cloud Vision, Google Cloud Firestore, and Google Cloud Storage.",
        image: require("../../../assets/images/onboard-image.jpg"),
        altImage: require("../../../assets/images/onboard-image.jpg"),
        youtube: "https://www.youtube.com/watch?v=ahGbzTnvr5Y",
        tags: "kotlin,firebase,android,machinelearning",
    },
    {
        id: "livinglab",
        year: 2022,
        title: "Living Lab Dashboard",
        description:
            "The Living Lab Dashboard allows the monitoring and control of lab activities, smart devices and energy consumption in a building integrated with a smart building management system to ultimately maintain an optimal building condition. This is a large project which involved 30 people to work on different modules of the project, which will be integrated together at the end.",
        image: require("../../../assets/images/livinglab-image.png"),
        altImage: require("../../../assets/images/livinglab-altimage.png"),
        github: "https://github.com/ict2106-lab-p1/main",
        tags: "asp.netcore,tailwind,softwaredesign",
    },
    {
        id: "carstep",
        year: 2021,
        title: "Project CarStep",
        description:
            "Project CarStep purpose is to aid in the logical thinking for kids above 6 years old by providing a block-programming platform in the Flask web application while integrated with a robotic car in which the child could see what was being executed.",
        image: require("../../../assets/images/carstep-image.jpg"),
        altImage: require("../../../assets/images/carstep-image.jpg"),
        github: "https://github.com/ICT2x01-P3-4/ict2x01-p3-4",
        youtube: "https://youtu.be/A5-hTmKdmNo",
        tags: "python,flask,roboticcar,msp432",
    },
    {
        id: "in10sit",
        year: 2021,
        title: "IN10SIT",
        description:
            "HR application project prototype for Human Computer Interaction ICT2102 based from our clients requirements.",
        image: require("../../../assets/images/in10sit-image.png"),
        altImage: require("../../../assets/images/in10sit-image.png"),
        tags: "react,nodejs,express,tailwindcss",
    },
    {
        id: "vaccation",
        year: 2021,
        title: "Vaccation",
        description:
            "Flight booking database system for ICT2103. This web application allows users to view and book a flight in times of Covid. It also serves as a portal to view Covid-19 related information of each country such as vaccination rates, Covid restrictions as well as PCR testing clinics available.",
        image: require("../../../assets/images/vaccation-image.png"),
        altImage: require("../../../assets/images/vaccation-altimage.png"),
        github: "https://github.com/ICT2103-Group29/vaccation",
        tags: "mern,react,nodejs,express,mysql",
    },
    {
        id: "slate",
        year: 2021,
        title: "S L A T E . bakery",
        description:
            "This is an ecommerce website built for my sister for her online bakery business. Due to the covid situation, my sister and a partner wanted to open up a home based bakery business, which they requested me for help to build a minimalistic web store for them. \n\nThis ecommerce store was built using Wordpress with most of the free themes and plugins. It is also my very first time using the Wordpress CMS!",
        image: require("../../../assets/images/slate-image.png"),
        altImage: require("../../../assets/images/slate-image.png"),
        url: "https://slatesingapore.com",
        tags: "wordpress,woocommerce",
    },
    {
        id: "sit-confessions",
        year: 2021,
        title: "SIT Confessions",
        description:
            "Me and my friend wanted to do a collaboration for a holiday project, and one thing that we couldn't find was an active Facebook/Instagram account for a confessions platform for SIT. \n\nSo we went ahead and made our very own one, its on Facebook complete with our own web application to go with it. We also developed a backend admin panel to moderate confessions, and it will auto post to our Facebook page (with every 15mins interval) after it has been approved. \n\nThe backend server is hosted on Heroku and client is hosted on Netlify.",
        image: require("../../../assets/images/sitc-image.jpg"),
        altImage: require("../../../assets/images/sitc-image.jpg"),
        url: "https://sitconfessions.com",
        github: "https://github.com/SIT-Confessions/sit-confessions",
        tags: "mern,fbapi,tailwind,cron",
    },
    {
        id: "new-portfolio",
        year: 2021,
        title: "New Portfolio",
        description:
            "My old website, which was developed in 2018, was kind of stale. Hence, I wanted a new change of look. I coded this website using ReactJS to test my understanding about the framework as I self-learnt it through online platforms. So here it is, my new portfolio coded in ReactJS :)",
        image: require("../../../assets/images/new-portfolio-image.jpg"),
        altImage: require("../../../assets/images/new-portfolio-image.jpg"),
        tags: "react,javascript,html,css,mui",
    },
    {
        id: "memories-app",
        year: 2021,
        title: "Memories App",
        description:
            'This App is called "Memories" and it is a simple social media app that allows users to post interesting events that happened in their lives. It allows all CRUD operations as well as file upload capability. It is built with the MERN stack and there is also Google authentication implemented. \n\nThe client side is hosted on Netlify and the server side is deployed to Heroku.',
        image: require("../../../assets/images/memories-app-image.png"),
        altImage: require("../../../assets/images/memories-app-image.png"),
        tags: "mern,netlify,heroku,googleauth",
        url: "https://hy-memories-app.netlify.app/",
        github: "https://github.com/nghanyi/memories-app",
    },
    {
        id: "amazon-clone",
        year: 2021,
        title: "Amazon Clone",
        description:
            "This App mimics the popular Amazon e-commerce website which has functionalities such as - User authentication, Product listing, Cart, and Payment. This project is built with ReactJS with Firebase and Stripe implementations. \n\nThe site is hosted on Firebase.",
        image: require("../../../assets/images/amazon-clone-image.png"),
        altImage: require("../../../assets/images/amazon-clone-altimage.jpg"),
        tags: "react,javascript,firebase,stripe",
        url: "https://fir-f9554.web.app/",
        github: "https://github.com/nghanyi/react-amazon-clone",
    },
    {
        id: "black-mamba",
        year: 2021,
        title: "Black Mamba",
        description:
            "This is a multiplayer snake game named Black Mamba to pay tribute to NBA star Kobe Bryant. \n\nThe game features two snakes trying to compete against each other by eating the most number of basketballs without dying. The is a hard mode also where there will be a few poisons randomly appearing within a time interval which will reduct the lives of the player if eaten. \n\nThis project is coded in Java initially and was later converted to C++ with added new features and optimisations.",
        image: require("../../../assets/images/blackmamba-image.JPG"),
        altImage: require("../../../assets/images/blackmamba-altimage.jpg"),
        tags: "java,c++,blackmamba,kobe",
        github: "https://github.com/nghanyi/BlackMamba-CPP",
    },
    {
        id: "routenote-analyser",
        year: 2021,
        title: "Routenote Analyser",
        description:
            "This is a simple console program to help me analyse my own music earnings from Route Note. It reads data from excel files generated by Route Note which filters and computes a summary of my earnings. The project is coded with Python and Pandas library.",
        image: require("../../../assets/images/routenote-image.jpg"),
        tags: "python,pandas",
        github: "https://github.com/nghanyi/routenote-automation",
    },
    {
        id: "dwarf-diary",
        year: 2020,
        title: "Dwarf Diary",
        description:
            'This is a microblogging social media website called "Dwarf Diary" in which the word "Dwarf" represents a mini version and "Diary" means penning down your daily events. Dwarf Diary allows users to share their thoughts with their friends online. Users can interact with their friends by liking and commenting on their blog post. \n\nThis project is built using the LAMP stack and hosted in AWS EC2. The deployed website has been taken down due to cost needed for AWS.',
        image: require("../../../assets/images/dwarf-diary-image.png"),
        altImage: require("../../../assets/images/dwarf-diary-altimage.jpg"),
        tags: "lamp,aws,ec2",
    },
    {
        id: "cleverbot",
        year: 2020,
        title: "Cleverbot",
        description:
            "This is a simple console chatbot program for a school project. The chatbot talks in Singlish and it allows small talks, questioning, load data, reset data. It is coded is C.",
        image: require("../../../assets/images/cleverbot-image.JPG"),
        tags: "c,chatbot",
    },
    {
        id: "resalehdb-analyser",
        year: 2020,
        title: "Resale HDB Analyser",
        description:
            "This is a program to help Singaporeans decide which area, town, and flat type to buy resale HDB flats. By allowing the user to view various types of graphs within the program, they can make better decisions on the area and type of HDB flat to buy that best suits their needs. \n\nThis project is coded in Python with Tkinter GUI implementation.",
        image: require("../../../assets/images/hdb-analyser-image.jpg"),
        altImage: require("../../../assets/images/hdb-analyser-altimage.jpg"),
        tags: "python,tkinter,pandas,numpy,matplotlib",
        github: "https://github.com/nghanyi/data-analysis-resale-hdb",
    },
    {
        id: "legacy-portfolio",
        year: 2018,
        title: "Legacy Portfolio",
        description:
            "This is a legacy version of my own portfolio website that was developed in 2018. When I first got my hands on to web development, I find it amazing to be able to access it anywhere which got me into creating this personal website. It is a static website built using HTML, CSS & jQuery. \n\nThe website is still hosted with Github pages.",
        image: require("../../../assets/images/legacy-portfolio-image.png"),
        altImage: require("../../../assets/images/legacy-portfolio-image.png"),
        tags: "html,css,jquery,bootstrap",
        url: "https://nghanyi.github.io/MyWebsite/",
    },
];
