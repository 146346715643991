import React, { useState } from "react";
import logo from "../../assets/images/logo.png";
import MenuIcon from "@material-ui/icons/Menu";
import ShareIcon from "@material-ui/icons/Share";

import { Link } from "react-scroll";
import { RWebShare } from "react-web-share";
import {
  makeStyles,
  Link as ExternalLink,
  SwipeableDrawer,
  ListItemIcon,
} from "@material-ui/core";
import { componentLinks, externalLinks } from "./data";
import {
  AppBar,
  Container,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";

const styles = makeStyles((theme) => ({
  appbar: {
    backgroundColor: "#1e1e1e",
    color: "#B9B9B9",
    height: 60,
    [theme.breakpoints.down("xs")]: {
      top: "auto",
      bottom: 0,
      position: "fixed",
    },
  },
  drawer: {
    background: "#1e1e1e",
    color: "#B9B9B9",
    width: "50%",
  },
  nav: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  navText: {
    textDecoration: "none",
    textTransform: "capitalize",
    color: "#fff",

    "& span": {
      letterSpacing: "1px",
    },
  },
  toolbar: {
    justifyContent: "center",
    position: "relative",
  },
  hamburgerBtn: {
    position: "absolute",
    left: "12px",
  },
  listIcon: {
    color: "#fff",
    width: "20px",
  },
}));

const Navbar = () => {
  const classes = styles();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const isMobileView = useMediaQuery(useTheme().breakpoints.down("xs"));

  const displayMobile = () => {
    const handleDrawerOpen = () => setDrawerOpen(true);
    const handleDrawerClose = () => setDrawerOpen(false);
    return (
      <Toolbar className={classes.toolbar}>
        <IconButton
          className={classes.hamburgerBtn}
          {...{
            edge: "start",
            color: "inherit",
            "aria-label": "menu",
            "aria-haspopup": "true",
            onClick: handleDrawerOpen,
          }}
        >
          <MenuIcon />
        </IconButton>
        <Link to="home" smooth>
          <IconButton aria-label="home" disabled>
            <img src={logo} alt="logo" width={50} />
          </IconButton>
        </Link>
        <SwipeableDrawer
          anchor="left"
          open={drawerOpen}
          onClose={handleDrawerClose}
          classes={{ paper: classes.drawer }}
        >
          <List>
            {componentLinks.map((item, i) => (
              <Link
                key={i}
                className={classes.navText}
                onClick={handleDrawerClose}
                to={item.link}
                smooth
              >
                <ListItem button>
                  <ListItemIcon className={classes.listIcon}>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={item.title} />
                </ListItem>
              </Link>
            ))}
            {externalLinks.map((item, i) => (
              <ExternalLink
                className={classes.navText}
                href={item.link}
                target="_blank"
                rel="noreferrer"
                key={i}
              >
                <ListItem button>
                  <ListItemIcon className={classes.listIcon}>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={item.title} />
                </ListItem>
              </ExternalLink>
            ))}
            <RWebShare
              data={{
                url: "https://www.nghanyi.com",
                title: "Web Developer, Ng Han Yi",
              }}
              onClick={handleDrawerClose}
            >
              <ListItem>
                <ListItemIcon className={classes.listIcon}>
                  <ShareIcon />
                </ListItemIcon>
                <ListItemText primary="Share" style={{ color: "#fff" }} />
              </ListItem>
            </RWebShare>
          </List>
        </SwipeableDrawer>
      </Toolbar>
    );
  };

  const displayDesktop = () => {
    return (
      <Toolbar>
        <Container className={classes.nav}>
          <Link to="home" smooth>
            <IconButton edge="end" aria-label="home" disabled>
              <img src={logo} alt="logo" width={50} />
            </IconButton>
          </Link>
          <List
            className={classes.nav}
            component="nav"
            aria-labelledby="main navigation"
          >
            {componentLinks.map((item, i) => (
              <Link key={i} className={classes.navText} to={item.link} smooth>
                <ListItem button>
                  <ListItemText primary={item.title} />
                </ListItem>
              </Link>
            ))}
            {externalLinks.map((item, i) => (
              <ExternalLink
                className={classes.navText}
                href={item.link}
                target="_blank"
                rel="noreferrer"
                key={i}
              >
                <ListItem button>
                  <ListItemText primary={item.title} />
                </ListItem>
              </ExternalLink>
            ))}
          </List>
        </Container>
      </Toolbar>
    );
  };
  return (
    <AppBar className={classes.appbar} position="sticky">
      {isMobileView ? displayMobile() : displayDesktop()}
    </AppBar>
  );
};

export default Navbar;
