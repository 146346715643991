import React, { useState } from "react";
import useStyles from "./style";
import emailjs from "emailjs-com";
import SendIcon from "@material-ui/icons/Send";
import ScrollAnimation from "react-animate-on-scroll";
import LinkedInIcon from "@material-ui/icons/LinkedIn";

import "animate.css/animate.compat.css";

import { BLUE } from "../../../constants/colors";
import {
  Grid,
  Paper,
  TextField,
  Button,
  CircularProgress,
  Snackbar,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

const Form = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [enableSend, setEnableSend] = useState(true);
  const [showAlert, setShowAlert] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    setEnableSend(false);
    setLoading(true);

    emailjs
      .sendForm(
        "gmail",
        "template_719cx2t",
        e.target,
        "user_AWKloc5Y77c4I8pCeHcQj"
      )
      .then(
        (result) => {
          setEnableSend(true);
          setLoading(false);
          setShowAlert(true);
          e.target.reset();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <Grid item sm={12} md={7}>
      <ScrollAnimation animateIn="fadeIn" duration={3} animateOnce>
        <Grid
          container
          item
          direction="row"
          alignItems="center"
          justify="space-between"
        >
          <Grid item>
            <h1>
              <span style={{ color: BLUE }}>Contact </span>me
            </h1>
          </Grid>
          <Grid>
            <Grid item>
              <Button
                className={classes.social}
                size="large"
                aria-label="linkedin"
                style={{ color: "#fff" }}
                target="_blank"
                href="https://www.linkedin.com/in/nghanyi"
              >
                <LinkedInIcon fontSize="large" />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeInTopRight">
        <Paper className={classes.paper}>
          <form
            autoComplete="off"
            className={`${classes.root} ${classes.form}`}
            onSubmit={sendEmail}
          >
            <TextField
              name="name"
              variant="outlined"
              label="Name"
              fullWidth
              required
            />
            <TextField
              name="email"
              variant="outlined"
              label="Email"
              fullWidth
              required
            />
            <TextField
              name="message"
              variant="outlined"
              label="Message"
              rows={5}
              fullWidth
              multiline
              required
            />
            <div className={classes.buttonWrapper}>
              <Button
                disabled={!enableSend}
                className={classes.buttonSubmit}
                variant="contained"
                type="submit"
                startIcon={<SendIcon />}
                fullWidth
              >
                Send Message
              </Button>
              {loading && (
                <CircularProgress
                  className={classes.buttonProgress}
                  size={24}
                />
              )}
            </div>
          </form>
        </Paper>
      </ScrollAnimation>
      <Snackbar
        open={showAlert}
        onClose={() => setShowAlert(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        autoHideDuration={2000}
      >
        <Alert severity="success">Email Sent!</Alert>
      </Snackbar>
    </Grid>
  );
};

export default Form;
