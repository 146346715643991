import { makeStyles } from "@material-ui/core/styles";
import { BLUE, GREY, BLACK } from "../../../../constants/colors";

export default makeStyles((theme) => ({
  media: {
    height: 20,
    paddingTop: "56.25%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  border: {
    border: "solid",
  },
  fullHeightCard: {
    height: "100%",
  },
  card: {
    background:
      "linear-gradient(140deg, rgba(2,0,36,1) 0%, rgba(69,149,235,1) 80%, rgba(0,181,255,1) 100%)",
    color: "#fff",
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    borderRadius: "15px",
    height: "100%",
    width: "100%",
    position: "relative",
    transition: "transform 0.15s ease-in-out",
    [theme.breakpoints.down("xs")]: {
      height: 83,
      justifyContent: "center",
      alignContent: "center",
    },
  },
  cardContent: {
    paddingBottom: 16,
  },
  cardTitle: {
    fontSize: "1em",
  },
  cardHovered: {
    transform: "scale3d(1.05, 1.05, 1)",
  },
  overlay: {
    position: "absolute",
    top: "20px",
    left: "20px",
    color: "white",
  },
  grid: {
    display: "flex",
  },
  details: {
    display: "flex",
    justifyContent: "space-between",
    margin: "20px",
  },
  title: {
    padding: "0 16px",
  },
  contentDescription: {
    fontSize: "1em",
    lineHeight: 2,
    whiteSpace: "pre-line",
  },
  closeButton: {
    backgroundColor: GREY,
    color: BLUE,
    fontWeight: 600,
    borderRadius: 0,
    padding: "10px 15px",
    [theme.breakpoints.down("xs")]: {
      height: "50px",
    },
    "&:hover": {
      backgroundColor: GREY,
      color: BLUE,
    },
  },
  visitButton: {
    backgroundColor: BLUE,
    color: BLACK,
    "&:hover": {
      backgroundColor: BLUE,
      color: BLACK,
      transform: "scale(1.01)",
      transition: "0.2s ease-out",
    },
  },
  sourceCodeButton: {
    color: BLUE,
    border: "1px solid",
    borderColor: BLUE,
    "&:hover": {
      transform: "scale(1.01)",
      transition: "0.2s ease-out",
    },
  },
  tags: {
    display: "flex",
    justifyContent: "flext-start",
    flexWrap: "wrap",
    marginRight: "auto",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
  tag: {
    background: BLUE,
    color: BLACK,
  },
  year: {
    backgroundColor: "#454443",
    color: "#fff",
    fontSize: 12,
  },
  dialogContent: {
    background: BLACK,
    color: "#fff",
  },
}));
