import React from "react";
import Item from "./Item/Item";
import Carousel from "react-slick";
import ScrollAnimation from "react-animate-on-scroll";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "animate.css/animate.compat.css";

import { Grid, makeStyles } from "@material-ui/core";
import { xsImages, smImages, mdImages } from "./images";

const styles = makeStyles((theme) => ({
  gallery: {
    width: "100%",
  },
  carousel: {
    "& .slick-dots li button:before": {
      color: "#ddd",
    },
    "& .slick-dots li.slick-active button:before": {
      color: "#fff",
    },
    paddingTop: "16px",
  },
}));

const Gallery = () => {
  const classes = styles();

  return (
    <Grid className={classes.gallery} item sm={12} md={4}>
      <ScrollAnimation animateIn="fadeInRight" duration={2}>
        <Carousel
          className={classes.carousel}
          dots
          infinite
          autoplay
          speed={500}
          arrows={false}
          slidesToShow={1}
          slidesToScroll={1}
        >
          {Array(smImages.length)
            .fill(0)
            .map((_, i) => (
              <Item
                key={i}
                xsImage={xsImages[i]}
                smImage={smImages[i]}
                mdImage={mdImages[i]}
              />
            ))}
        </Carousel>
      </ScrollAnimation>
    </Grid>
  );
};

export default Gallery;
