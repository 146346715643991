import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import image from "../../../assets/images/email-man.png";

import "animate.css/animate.compat.css";

import { Grid } from "@material-ui/core";

const Image = () => {
  return (
    <Grid item sm={4}>
      <ScrollAnimation animateIn="fadeInTopLeft" duration={2} delay={200}>
        <img src={image} alt="mail-man" width="100%" />
      </ScrollAnimation>
    </Grid>
  );
};

export default Image;
