import React from "react";
import useStyles from "./style";
import { BLUE, BLACK } from "../../../../constants/colors";
import {
  Button,
  Chip,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  Zoom,
  useTheme,
  useMediaQuery,
  Slide,
} from "@material-ui/core";

const Transition = React.forwardRef(function Transition(props, ref) {
  const isMobile = useMediaQuery(useTheme().breakpoints.down("xs"));
  return (
    <>
      {isMobile ? (
        <Slide direction="up" ref={ref} {...props} />
      ) : (
        <Zoom ref={ref} {...props} />
      )}
    </>
  );
});

const Popup = (props) => {
  const classes = useStyles();
  const isMobileView = useMediaQuery(useTheme().breakpoints.down("xs"));
  const { project, openPopup, setOpenPopup } = props;

  const closeButton = () => {
    return (
      <Button
        className={classes.closeButton}
        variant="contained"
        fullWidth
        onClick={() => setOpenPopup(false)}
      >
        Close
      </Button>
    );
  };

  return (
    <Dialog
      open={openPopup}
      onClose={() => setOpenPopup(false)}
      TransitionComponent={Transition}
      maxWidth="lg"
      fullWidth
      fullScreen={isMobileView}
    >
      {isMobileView && closeButton()}
      {isMobileView && project.altImage && (
        <img src={project.altImage.default} width="100%" alt="Project" />
      )}
      <DialogContent className={classes.dialogContent}>
        <Grid container spacing={1}>
          {!isMobileView && project.altImage && (
            <Grid item sm={12}>
              <img src={project.altImage.default} width="100%" alt="Project" />
            </Grid>
          )}
          <Grid item sm={12}>
            <Typography variant="h6">{`${project.title} ${project.year}`}</Typography>
          </Grid>
          <Grid
            container
            item
            direction="row"
            justify="center"
            alignItems="center"
            spacing={1}
          >
            {(project.url || project.youtube) && (
              <Grid item xs={project.github ? 6 : 12}>
                <Button
                  className={classes.visitButton}
                  variant="contained"
                  href={project.url || project.youtube}
                  target="_blank"
                  fullWidth
                >
                  {project.url ? "Visit Website" : "Watch Demo"}
                </Button>
              </Grid>
            )}
            {project.github && (
              <Grid item xs={project.url || project.youtube ? 6 : 12}>
                <Button
                  className={classes.sourceCodeButton}
                  variant="outlined"
                  href={project.github}
                  target="_blank"
                  fullWidth
                >
                  View Code
                </Button>
              </Grid>
            )}
          </Grid>
          <Grid item sm={12}>
            <Typography className={classes.contentDescription} variant="body2">
              {project.description}
            </Typography>
          </Grid>
          <Grid className={classes.tags} item sm={12}>
            {project.tags.split(",").map((tag, i) => (
              <Chip
                key={i}
                label={`#${tag}`}
                variant="default"
                size="small"
                style={{ background: BLUE, color: BLACK }}
              />
            ))}
          </Grid>
        </Grid>
      </DialogContent>
      {!isMobileView && closeButton()}
    </Dialog>
  );
};

export default Popup;
