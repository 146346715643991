import { makeStyles } from "@material-ui/core/styles";
import { BLUE } from "../../../constants/colors";

export default makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  paper: {
    padding: theme.spacing(2),
  },
  form: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  buttonWrapper: {
    position: "relative",
  },
  buttonSubmit: {
    color: "#111",
    backgroundColor: BLUE,
    "&:hover": {
      backgroundColor: "#3590f2",
    },
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  social: {
    "&:hover": {
      transform: "scale(1.1)",
      transition: "0.2s ease-out",
    },
  },
}));
