import React from "react";
import Project from "./Project/Project";
import ScrollAnimation from "react-animate-on-scroll";

import "animate.css/animate.compat.css";

import { BLUE } from "../../../constants/colors";
import { Box, Grid, useTheme, useMediaQuery } from "@material-ui/core";
import { projectData } from "./data";

const Projects = () => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down("xs"));
  return (
    <Box id="projects" textAlign="center" pt={8}>
      <ScrollAnimation animateIn="bounceIn" animateOnce>
        <h1>
          My <span style={{ color: BLUE }}>projects</span>
        </h1>
      </ScrollAnimation>
      <Grid
        container
        direction="row"
        alignItems="center"
        justify="flex-start"
        spacing={isMobile ? 2 : 3}
      >
        {projectData
          .sort((a, b) => b.year - a.year)
          .map((proj) => (
            <Grid key={proj.id} item xs={6} sm={6} md={4}>
              <ScrollAnimation animateIn="bounceIn">
                <Project project={proj} />
              </ScrollAnimation>
            </Grid>
          ))}
      </Grid>
    </Box>
  );
};

export default Projects;
