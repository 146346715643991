import React from "react";
import logo from "../../assets/images/logo.png";

import "./Footer.css";

import { Grid } from "@material-ui/core";
import { Link } from "react-scroll";

const Footer = () => {
  return (
    <>
      <div className="copyright">
        <footer>Ng Han Yi © {new Date().getFullYear()} </footer>
      </div>
      <Grid
        container
        direction="column"
        className="footer"
        justify="center"
        alignItems="center"
      >
        <Link to="home" smooth>
          <Grid item>
            <img src={logo} alt="logo" width="50" />
          </Grid>
        </Link>
      </Grid>
    </>
  );
};

export default Footer;
