import React, { useState, useEffect } from "react";
import Typed from "react-typed";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import ScrollAnimation from "react-animate-on-scroll";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import profilepic from "../../assets/images/profile-pic.jpg";

import "./Home.css";
import "animate.css/animate.compat.css";

import { BLUE, GREY } from "../../constants/colors";
import { Link } from "react-scroll";
import { Button, Grid, Grow, makeStyles, Tooltip } from "@material-ui/core";

const style = makeStyles(() => ({
    button: {
        width: 180,
        height: 50,
        fontSize: 16,
        fontWeight: 600,
        marginLeft: 10,
        marginRight: 10,
        "&:hover": {
            transform: "scale(1.1)",
            transition: "0.2s ease-out",
        },
    },
    contactBtn: {
        background: BLUE,
        "&:hover": {
            background: BLUE,
        },
    },
    resumeBtn: {
        background: GREY,
        color: BLUE,
        "&:hover": {
            background: GREY,
        },
    },
}));

const Home = () => {
    const classes = style();
    const [start, setStart] = useState(false);
    const [btnStart, setBtnStart] = useState(false);

    const handleTitleComplete = (self) => {
        self.cursor.remove();
        setStart(true);
    };

    const handleMsgComplete = () => {
        setBtnStart(true);
        setTimeout(() => {
            document.body.style.overflow = "";
        }, 500);
    };

    useEffect(() => {
        document.body.style.overflow = "hidden";
    }, []);

    return (
        <div className='home' id='home'>
            <div className='home__background'>
                <p>WEB DEV</p>
            </div>
            <Grid
                container
                direction='column'
                alignItems='center'
                style={{ minHeight: "325px" }}
            >
                <Grid item>
                    <Typed
                        className='home__title'
                        strings={[
                            `Hi, I'm <span style='color:${BLUE}'>Han Yi</span>`,
                        ]}
                        typeSpeed={25}
                        cursorChar='_'
                        onComplete={handleTitleComplete}
                    />
                </Grid>
                {start && (
                    <Grid item>
                        <Typed
                            className='home__body'
                            strings={["Welcome to my website!"]}
                            cursorChar='_'
                            typeSpeed={20}
                            onComplete={handleMsgComplete}
                        />
                    </Grid>
                )}

                {btnStart && (
                    <>
                        <ScrollAnimation animateIn='fadeInDown' animateOnce>
                            <Grid item>
                                <Link to='about' duration={1000} smooth>
                                    <Tooltip
                                        placement='right'
                                        title='Find out more about me!'
                                        TransitionComponent={Grow}
                                        arrow
                                    >
                                        <img
                                            className='home__profilepic'
                                            src={profilepic}
                                            alt='My Profile Pic'
                                        />
                                    </Tooltip>
                                </Link>
                            </Grid>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn='fadeInUp' animateOnce>
                            <Grid
                                container
                                item
                                sm={12}
                                justify='center'
                                alignItems='center'
                                direction='row'
                            >
                                <Grid item>
                                    <Link to='contact' duration={1000} smooth>
                                        <Button
                                            className={`${classes.button} ${classes.contactBtn}`}
                                            variant='contained'
                                            startIcon={<AccountCircleIcon />}
                                        >
                                            Contact
                                        </Button>
                                    </Link>
                                </Grid>
                                <Grid item>
                                    <Button
                                        className={`${classes.button} ${classes.resumeBtn}`}
                                        varient='contained'
                                        startIcon={<CloudDownloadIcon />}
                                        href='https://nghanyi-personal-website.s3.ap-southeast-1.amazonaws.com/HanYi_Resume.pdf'
                                        download='Resume_HanYi.pdf'
                                    >
                                        Resume
                                    </Button>
                                </Grid>
                            </Grid>
                        </ScrollAnimation>
                    </>
                )}
            </Grid>
        </div>
    );
};

export default Home;
